p {
  font-weight: $fw-regular;
  margin-bottom: $gtr;
}

strong {
  font-weight: $fw-bold;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $fw-bold;
  font-variation-settings: $ff-heading-settings;
  margin: 0;
}

h1 {
  font-size: rem(36);

  @include media($m-screen-up) {
    font-size: rem(75);
  }
}

h2 {
  font-size: rem(32);

  @include media($m-screen-up) {
    font-size: rem(60);
  }
}

h3 {
  font-size: rem(28);

  @include media($m-screen-up) {
    font-size: rem(50);
  }
}

h4 {
  font-size: rem(24);

  @include media($m-screen-up) {
    font-size: rem(40);
  }
}

h5 {
  font-size: rem(20);

  @include media($m-screen-up) {
    font-size: rem(30);
  }
}

h6 {
  font-size: rem(18);

  @include media($m-screen-up) {
    font-size: rem(25);
  }
}
