// Typography
$ff: 'Fraunces', serif;
$ff-default-settings: 'opsz' 40, 'SOFT' 50, 'WONK' 0;
$ff-heading-settings: 'opsz' 100, 'SOFT' 85, 'WONK' 1;
$fw-bold: 600;
$fw-regular: 300;

// Colors
$black: #303133;
$primary: #75aadb;
$secondary: #fcbf49;
$off-white: #f9f2e6;
$white: #FFFFFF;

// Spacing
$gtr               : rem(15);
$max-width-wide    : rem(1080);
