.img {
  margin-top: $gtr * 3;

  &--float-right {
    @include media($m-screen-up) {
      width: 50%;
      float: right;
      margin: ($gtr * 4) 0 ($gtr * 4) ($gtr * 3);
    }
  }

  &--float-left {
    @include media($m-screen-up) {
      width: 50%;
      float: left;
      margin: ($gtr * 4) ($gtr * 3) ($gtr * 4) 0;
    }
  }
}
