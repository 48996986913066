.footer {
  .js & { opacity: 0; }

  font-size: rem(16);
  padding-top: $gtr;
  @include containerPadding;

  @include media($m-screen-up) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__links {
    @include listReset;
    margin-bottom: $gtr;

    @include media($m-screen-up) {
      margin-bottom: 0;
    }

    li {
      display: inline-block;

      &::after {
        content: "/";
        color: $secondary;
        margin-left: $gtr / 2;
        margin-right: $gtr / 2;
      }

      &:last-child::after {
        content: "";
        margin: 0;
      }
    }

    a {
      @include linkUnderline;
    }
  }
}
