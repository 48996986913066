.masthead {
  p {
    font-variation-settings: 'opsz' 140, 'SOFT' 85, 'WONK' 0;
    font-size: rem(28);

    @include media($m-screen-up) {
      font-size: rem(60);
      line-height: 1.2;
    }
  }

  a {
    @include linkUnderline;
  }
}
